<template>
  <div class="row justify-content-center">
    <div class="col-md-6">
      <div class="card mx-2">
        <div class="text-center">
          <img src="./../../public/loonity/img/uc.jpg" class="img-fluid" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "UC",
};
</script>
